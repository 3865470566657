import React, { Component } from 'react'
import { isMobile } from 'react-device-detect'
import moment from 'moment'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import {
  NOTIFY_EXPIRING_CARD_MODAL,
  EXPIRING_CARD_LAST_NOTIFIED,
  FEATURE_LIMIT_MODAL,
  NO_PLAN_MODAL
} from 'constants/account'
import { TRANSITION_TIMING } from 'constants/sidebar'
import { LISTING_GRADER_BASE, DASHBOARD_URL } from 'constants/routes'
import { NAVBAR_STYLE } from 'constants/styles'

import { shouldNotifyExpiringCard } from 'helpers/account'
import { clearGlobalData } from 'helpers/storage'

import { FeatureLimitModal } from 'ui_elements/FeatureLimitModal'

import { getCookie } from 'services/cookies'
import { setSiftSession } from 'services/sift'

import LoadingPage from 'components/common/LoadingPage'
import { Navbar } from 'components/navbar/Navbar/Navbar'
import { UpgradeSearchLimitModal } from 'components/feature_limit_modals/SearchLimitModal/UpgradeSearchLimitModal'

import Routes from '../routes'

import { NoPlanModal } from './feature_limit_modals/NoPlanModal/NoPlanModal'
import ExpiringCreditCardModal from './account/ExpiringCreditCardModal'
import MwsSyncStatusbar from './MwsSyncStatusbar'
import Sidebar from './sidebar'
import { MobileMenu } from './mobile_menu/MobileMenu'
import { TopBanner } from 'ui_elements/TopBanner/TopBanner'
import { CIUpgradeBanner } from './navbar/CIUpgradeBanner'
import { MembershipStatusBanners } from './banners/MembershipStatusBanners'

const PAGES_WITHOUT_NAVBAR = [
  '/login',
  'setup',
  'password',
  'amazing',
  'add-user',
  'extension-uninstall',
  'onboard',
  'registrations',
  'checkout',
  'cancel',
  '/cancel-confirm',
  '/404-page',
  'unsubscribe_email',
  /\/$/
]

// TODO - this is quite hacky
const PAGES_WITH_PADDING = ['/admin']

const getRouterPadding = ({ locked, isMobileNavEnabled }) => {
  if (isMobileNavEnabled) return 0

  return locked ? NAVBAR_STYLE.width : NAVBAR_STYLE.collapsedWidth
}

const TopSection = styled.div`
  width: 100vw;
  position: fixed;
  top: 0;
  z-index: 100000;
  padding-left: ${props =>
    getRouterPadding({
      locked: props.locked,
      isMobileNavEnabled: props.isMobileNavEnabled
    })};
  transition: all 0.3s ease;
`

const StyledContent = styled.div`
  // rewrites the margin-top: -10px on the legacy SASS file
  ${props => props.isMobileNavEnabled && `margin-top: 0 !important`}
`

const RouterWrapper = styled.div`
  position: relative;
  padding: ${props => (props.hasPadding ? '30px' : 'unset')};
  transition: margin-left 0.6s ease;
  width: 100%;
  margin-top: ${props => `${props.topMargin}px`};
  overflow: visible;
  padding-left: ${props =>
    getRouterPadding({
      locked: props.locked,
      isMobileNavEnabled: props.isMobileNavEnabled
    })};
  transition: padding-left 0.3s ${TRANSITION_TIMING};
`

class AppComp extends Component {
  state = {
    isFullScreenOverlay: false,
    topMargin: 48
  }

  constructor(props) {
    super(props)
    // this is a bit odd in react land, but it saves passing state all over the place and having multiple components having to touch global data etc
    window.addEventListener('fullScreenOverlay', event =>
      this.setState({ isFullScreenOverlay: event.detail })
    )
  }

  componentDidMount() {
    const { pathname } = this.props.location

    if (getCookie(process.env.REACT_APP_AUTH_TOKEN_COOKIE)) {
      const {
        loadGlobalData,
        loadChecklistData,
        fetchCardInfo,
        getAmazonSellerAccounts,
        addIdentifyRequest,
        identifyRequestsAreInProgress,
        executeIdentifyRequests
      } = this.props

      loadGlobalData()
      loadChecklistData()
      fetchCardInfo()
      getAmazonSellerAccounts()

      this.trackServerSideIdentify(
        addIdentifyRequest,
        identifyRequestsAreInProgress,
        executeIdentifyRequests
      )
    } else {
      clearGlobalData()
    }
    this.props.addLocationHistory(pathname)

    setSiftSession()
    this.calculateTopMargin()
  }

  componentDidUpdate(prevProps) {
    const { locationHistory, loggedIn } = prevProps.sessions

    const {
      banners: { banners },
      location: { pathname },
      updateActivePpcAccounts,
      addLocationHistory,
      globalData: {
        user,
        membershipInfo: { hasActiveMembership },
        pendo,
        modal,
        isShowingCIUpgradeBanner
      },
      cardInfo,
      loadGlobalData,
      loadChecklistData,
      sessions,
      fetchCardInfo,
      getAmazonSellerAccounts,
      setGlobalModal,
      addIdentifyRequest,
      identifyRequestsAreInProgress,
      executeIdentifyRequests,
      isStatusBarVisible
    } = this.props

    if (locationHistory !== pathname) {
      updateActivePpcAccounts(pendo.account.activePPCAccounts)
      addLocationHistory(pathname)
    }

    if (
      getCookie(process.env.REACT_APP_AUTH_TOKEN_COOKIE) &&
      !loggedIn &&
      !!sessions.loggedIn
    ) {
      loadGlobalData()
      fetchCardInfo()
      getAmazonSellerAccounts()

      this.trackServerSideIdentify(
        addIdentifyRequest,
        identifyRequestsAreInProgress,
        executeIdentifyRequests
      )
    }

    if (
      shouldNotifyExpiringCard(user, hasActiveMembership, cardInfo) &&
      modal !== NOTIFY_EXPIRING_CARD_MODAL
    ) {
      window.localStorage.setItem(
        EXPIRING_CARD_LAST_NOTIFIED,
        moment(Date.now()).format('YYYY-MM-DD')
      )
      setGlobalModal(NOTIFY_EXPIRING_CARD_MODAL)
    }

    const prevObjective = prevProps.globalData?.user?.objective
    const currObjective = user?.objective

    if (prevObjective !== currObjective) {
      loadChecklistData()
    }

    if (
      prevProps.isStatusBarVisible !== isStatusBarVisible ||
      prevProps.globalData.isShowingCIUpgradeBanner !==
        isShowingCIUpgradeBanner ||
      prevProps.banners.banners.length !== banners.length
    ) {
      // ensures the top margin is recalculated after re-rendering
      setTimeout(() => {
        this.calculateTopMargin()
      }, 0)
    }
  }

  calculateTopMargin = () => {
    const bannersAndNavbar = document.getElementById('top-section')
    let topMargin = bannersAndNavbar ? bannersAndNavbar.clientHeight : 48

    if (
      this.props.trialAlerts?.launch?.daysRemaining &&
      this.props.membershipInfo?.membershipStatus !== 0
    ) {
      topMargin += 66
    }

    if (this.props.isShowingCIUpgradeBanner) {
      topMargin += 48
    }

    this.setState({
      ...this.state,
      topMargin
    })
  }

  trackServerSideIdentify = (
    addIdentifyRequest,
    identifyRequestsAreInProgress,
    executeIdentifyRequests
  ) => {
    addIdentifyRequest({
      traits: {
        languageBrowser: window?.navigator?.language
      }
    })

    if (!identifyRequestsAreInProgress()) {
      executeIdentifyRequests()
    }
  }

  hasPadding() {
    // TODO - I don't really like this - it's a bit hacky, but for speed/simplicity it is what it is for now
    // it's basically the same way we do routes without navbars
    // according to design, the plan is everything should end up with this padding once there's time to do this
    const { pathname } = this.props.history.location

    return PAGES_WITH_PADDING.some(path => pathname.startsWith(path))
  }

  renderContent = () => {
    const {
      history: {
        location: { pathname }
      }
    } = this.props

    // Test if route needs a navbar
    const renderNavbar = !PAGES_WITHOUT_NAVBAR.reduce(
      (a, b) => a || RegExp(b).test(pathname),
      false
    )

    if (renderNavbar) {
      return this.renderNavbarElement()
    }

    return this.renderNoNavbarElement()
  }

  renderNavbarElement = () => {
    const {
      banners: bannersState,
      globalData: {
        initialLoad,
        employeeWithAccess,
        user,
        isImpersonating,
        flagData,
        membershipInfo,
        appType,
        featureLimits
      },
      sidebar: { collapsed, locked, ignoreToggleState, expandedOption },
      toggleSidebarLocked,
      expandSidebarOption,
      push,
      location
    } = this.props

    const isMobileNavEnabled = isMobile

    const { isFullScreenOverlay } = this.state
    const { selected_language } = user
    const wordWrappingLanguages = ['es-ES', 'fr-FR']
    const enableWrapping = wordWrappingLanguages.includes(selected_language)

    if (
      initialLoad?.isLoading &&
      getCookie(process.env.REACT_APP_AUTH_TOKEN_COOKIE)
    ) {
      return <LoadingPage />
    }

    return (
      <>
        <TopSection
          id="top-section"
          locked={locked}
          isMobileNavEnabled={isMobileNavEnabled}>
          <CIUpgradeBanner isMobile={isMobileNavEnabled} />
          {bannersState.banners?.map(banner => (
            <TopBanner
              key={banner.message}
              message={banner.message}
              type={banner.type || 'warning'}
              dismissible={banner.dismissible}
              showAfter={banner.showAfter}
              id={banner.id}
              isCustom={banner.isCustom}
            />
          ))}

          <Navbar
            isImpersonating={isImpersonating}
            isFullScreenOverlay={isFullScreenOverlay}
            user={user}
            appType={appType}
          />

          <MwsSyncStatusbar
            sidebarOpen={locked}
            membershipInfo={membershipInfo}
            history={this.props.history}
          />
        </TopSection>

        <StyledContent id="app-content" isMobileNavEnabled={isMobileNavEnabled}>
          {!isFullScreenOverlay && !isMobileNavEnabled && (
            <Sidebar
              collapsed={collapsed}
              locked={locked}
              ignoreToggleState={ignoreToggleState}
              admin={employeeWithAccess}
              expandedOption={expandedOption}
              toggleSidebar={toggleSidebarLocked}
              expandSidebarOption={expandSidebarOption}
              currentPath={location.pathname}
              flagData={flagData}
              membershipInfo={membershipInfo}
              user={user}
              appType={appType}
              push={push}
              enableWrapping={enableWrapping}
              isImpersonating={isImpersonating}
              featureLimits={featureLimits}
            />
          )}
          {isMobileNavEnabled && !isFullScreenOverlay && (
            <MobileMenu
              collapsed={collapsed}
              locked={locked}
              ignoreToggleState={ignoreToggleState}
              admin={employeeWithAccess}
              expandedOption={expandedOption}
              toggleSidebar={toggleSidebarLocked}
              expandSidebarOption={expandSidebarOption}
              currentPath={location.pathname}
              flagData={flagData}
              membershipInfo={membershipInfo}
              user={user}
              push={push}
              enableWrapping={enableWrapping}
            />
          )}
          <RouterWrapper
            collapsed={collapsed}
            locked={locked}
            ignoreToggleState={ignoreToggleState}
            isMobileNavEnabled={isMobileNavEnabled}
            topMargin={this.state.topMargin}
            hasPadding={this.hasPadding()}>
            <MembershipStatusBanners />
            <Routes {...this.props} />
          </RouterWrapper>
        </StyledContent>
      </>
    )
  }

  renderNoNavbarElement = () => {
    return (
      <div>
        <Routes {...this.props} />
      </div>
    )
  }

  renderFeatureLimitModals = () => {
    return (
      <>
        <UpgradeSearchLimitModal />
      </>
    )
  }

  renderGlobalModal = modal => {
    const {
      setGlobalModal,
      history,
      location: { pathname },
      account: { gatedFeature },
      cardInfo
    } = this.props

    if (modal === NOTIFY_EXPIRING_CARD_MODAL) {
      return (
        <ExpiringCreditCardModal
          setGlobalModal={setGlobalModal}
          history={history}
          cardInfo={cardInfo}
        />
      )
    }

    if (modal === NO_PLAN_MODAL) {
      return <NoPlanModal />
    }

    // In Listing Builder we show a custom feature limit modal. The check here
    // is to prevent the global feature limit modal from showing up there
    if (
      modal === FEATURE_LIMIT_MODAL &&
      pathname !== LISTING_GRADER_BASE &&
      pathname !== DASHBOARD_URL &&
      !pathname.match(/keyword\/listings\/[0-9]+/)
    ) {
      return (
        <FeatureLimitModal
          toggleModal={() => setGlobalModal(null)}
          limitType={gatedFeature?.limit_type}
          scope={gatedFeature?.scope}
        />
      )
    }

    return null
  }

  render() {
    const { globalData } = this.props

    return (
      <div className="app">
        {this.renderGlobalModal(globalData.modal)}
        {this.renderFeatureLimitModals()}
        {this.renderContent()}
      </div>
    )
  }
}

AppComp.propTypes = {
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  setGlobalModal: PropTypes.func,
  globalData: PropTypes.shape({
    membershipInfo: PropTypes.shape({
      payment_plan: PropTypes.string
    }),
    user: PropTypes.shape({
      selected_language: PropTypes.string
    }),
    flagData: PropTypes.oneOfType([PropTypes.object])
  })
}

AppComp.defaultProps = {
  globalData: {
    membershipInfo: {
      payment_plan: ''
    }
  }
}

export default AppComp
